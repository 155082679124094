import { createStore } from "vuex";
import router from "@/router";
function getInitialState() {
  return {
    pointsRemain: null,
    filterHistory: "all",
    category: "",
    dataGenerated: [],
    listOfProducts: [],
    isListOfProductsDisabled: false,
    snackbarQueue: [],
    snackbarMessage: "",
    snackbarVisible: false,
    refreshHistory: false,
    managementWay: localStorage.getItem("managementWay") || "image",
    productsToEnhance: [],
    subscribeInfo: null,
    subscribeInfoLoader: true,
    haveBasicSubscribe: false,
    showCreditsWarning: false,
    profileUploaded: false,
    newUser: false,
  };
}
export default createStore({
  state: getInitialState(),
  getters: {
    category: (state) => state.category,
    dataGenerated: (state) => state.dataGenerated,
    productsToEnhance: (state) => state.productsToEnhance,
    listOfProducts: (state) => state.listOfProducts,
    isListOfProductsDisabled: (state) => state.isListOfProductsDisabled,
    locale: (state) => state.locale,
    snackbarMessage: (state) => state.snackbarMessage,
    snackbarVisible: (state) => state.snackbarVisible,
  },
  mutations: {
    changeManagementWay(state, newValue) {
      state.managementWay = newValue;
    },
    SetNewUser(state, newValue) {
      state.newUser = newValue;
    },

    setPointsRemain(state, newValue) {
      state.pointsRemain = newValue;
    },
    setShowCreditsWarning(state, newValue) {
      state.showCreditsWarning = newValue;
    },
    setProfileUploaded(state, newValue) {
      state.profileUploaded = newValue;
    },

    updateCategory(state, newValue) {
      state.category = newValue;
    },

    clearUser(state) {
      localStorage.clear();
      Object.assign(state, getInitialState());
      router.push("/login");
    },
    setDataGenerated(state, data) {
      state.dataGenerated = data;
    },
    setSubscribeInfo(state, data) {
      state.subscribeInfo = data;
    },
    setSubscribeInfoLoader(state, data) {
      state.subscribeInfoLoader = data;
    },
    setHaveBasicSubscribe(state, data) {
      state.haveBasicSubscribe = data;
    },
    updateProductsToEnhance(state, products) {
      state.productsToEnhance = products;
    },
    addProductsToEnhance(state, product) {
      state.productsToEnhance.push(product);
    },
    removeProductsToEnhance(state, productId) {
      state.productsToEnhance = state.productsToEnhance.filter(
        (product) => product.id !== productId
      );
    },
    clearProductsToEnhance(state) {
      state.productsToEnhance = [];
    },

    setFilterHistory(state, filter) {
      state.filterHistory = filter;
    },

    setListOfProducts(state, products) {
      state.listOfProducts = products;
    },
    setIsListOfProductsDisabled(state, products) {
      state.isListOfProductsDisabled = products;
    },
    setLocale(state, locale) {
      localStorage.setItem("locale", locale);
      state.locale = locale;
    },
    ADD_SNACKBAR(state, message) {
      const id = Date.now();
      state.snackbarQueue.push({ id, message });
    },
    REMOVE_SNACKBAR(state, id) {
      state.snackbarQueue = state.snackbarQueue.filter(
        (snackbar) => snackbar.id !== id
      );
    },
    setRefreshHistory(state, value) {
      state.refreshHistory = value;
    },
  },
  actions: {
    logoutUser({ commit }) {
      commit("clearUser");
    },
    filterHistory({ commit }, filter) {
      commit("setFilterHistory", filter);
    },

    setLocale({ commit }, locale) {
      commit("Setlocale", locale);
    },
    showError({ commit }, message) {
      const id = Date.now();
      commit("ADD_SNACKBAR", { id, message });
      setTimeout(() => {
        commit("REMOVE_SNACKBAR", id);
      }, 6000);
    },
    hideSnackbar({ commit }) {
      commit("HIDE_SNACKBAR");
    },
    triggerHistoryRefresh({ commit }, payload) {
      commit("setRefreshHistory", payload);
    },
  },
});
