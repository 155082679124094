import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/scss/Main.scss";
import VueAxios from "vue-axios";
import axios from "axios";
import { createVuetify } from "vuetify";
import {
  VBtn,
  VCard,
  VDialog,
  VBottomNavigation,
  VCol,
  VRow,
  VContainer,
  VApp,
  VOverlay,
  VProgressCircular,
  VTooltip,
  VTextField,
  VDivider,
  VCardText,
  VCardActions,
  VSpacer,
  VMenu,
  VList,
  VListItem,
  VListItemTitle,
  VLabel,
  VLayout,
  VPagination,
  VListGroup,
  VListItemAction,
  VForm,
  VImg,
  VNavigationDrawer,
  VToolbar,
  VToolbarTitle,
  VToolbarItems,
  VAppBar,
  VListImg,
  VAlert,
  VSnackbar,
  VCardTitle,
  VSlider,
  VExpansionPanels,
  VExpansionPanel,
  VExpansionPanelTitle,
  VExpansionPanelText,
  VSkeletonLoader,
  VSwitch,
  VBreadcrumbs,
} from "vuetify/components";

import "vuetify/styles";

const vuetify = createVuetify({
  components: {
    VBtn,
    VCard,
    VDialog,
    VBottomNavigation,
    VCol,
    VRow,
    VContainer,
    VApp,
    VOverlay,
    VProgressCircular,
    VTooltip,
    VTextField,
    VDivider,
    VCardText,
    VCardActions,
    VSpacer,
    VMenu,
    VList,
    VListItem,
    VListItemTitle,
    VLabel,
    VLayout,
    VPagination,
    VListGroup,
    VListItemAction,
    VForm,
    VImg,
    VNavigationDrawer,
    VToolbar,
    VToolbarTitle,
    VToolbarItems,
    VAppBar,
    VListImg,
    VAlert,
    VSnackbar,
    VCardTitle,
    VSlider,
    VExpansionPanels,
    VExpansionPanel,
    VExpansionPanelTitle,
    VExpansionPanelText,
    VSkeletonLoader,
    VSwitch,
    VBreadcrumbs,
  },
  theme: {
    defaultTheme: "light",
    themes: {
      light: {
        colors: {
          primary: "#1677FF", // Set your primary color here
        },
      },
    },
  },
});
import i18n from "./plugins/i18n";

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
import Echo from "laravel-echo";

import Pusher from "pusher-js";
window.Echo = new Echo({
  broadcaster: "reverb",
  key: process.env.VUE_APP_REVERB_APP_KEY,
  wsHost: process.env.VUE_APP_REVERB_HOST,
  wsPort: process.env.VUE_APP_REVERB_PORT,
  wssPort: process.env.VUE_APP_REVERB_PORT,
  forceTLS: false,
  debug: true,
  // enabledTransports: ['ws', 'wss'],
  // Add reconnection options
  transports: ["websocket"],
  reconnectionAttempts: Infinity,
  reconnectionDelay: 1000,
  reconnectionDelayMax: 5000,
  timeout: 20000,
});

setTimeout(() => {
  window.Echo.connector.pusher.connection.bind("connected", () => {
    console.log("Successfully connected to Echo server.");
  });

  window.Echo.connector.pusher.connection.bind("disconnected", () => {
    console.log("disconnected from Echo server.");
    // socket.connect();
    window.Echo.connector.pusher.connection.connect();
  });

  // Listen for connection errors
  window.Echo.connector.pusher.connection.bind("error", () => {
    // socket.connect();
    window.Echo.connector.pusher.connection.connect();
    this.$store.dispatch("showError", "Error connecting to Echo server.");
  });
}, 2000);

createApp(App)
  .use(i18n)
  .use(store)
  .use(router)
  .use(VueAxios, axios)
  .use(vuetify)
  .mount("#app");
