<template>
  <v-app>
    <component :is="this.$route.meta?.layout + 'Layout' || 'div'">
      <router-view />
    </component>
    <listaproErrorMsg />
  </v-app>
</template>
<script>
import listaproErrorMsg from "./components/ui/listaproErrorMsg.vue";
import dashboardLayout from "./layouts/dashboardLayout.vue";
import defaultLayout from "./layouts/defaultLayout.vue";

export default {
  components: {
    listaproErrorMsg,
    dashboardLayout,
    defaultLayout,
  },
};
</script>
