import api from "@/plugins/axios";
import axios from "@/plugins/axios";

export function profile() {
  return api.get("/api/profile").then((response) => response.data);
}
export function login(payload) {
  return api.post("/api/login", payload).then((response) => response.data);
}
export function register(payload) {
  return api.post("/api/register", payload).then((response) => response.data);
}

export function contactUs(payload) {
  return axios.post("/api/contact", payload).then((response) => response.data);
}
export function reconnectShopify(payload) {
  return axios.post("/shopify_auth", payload).then((response) => response.data);
}

export function shopifyCallback(payload) {
  return axios
    .post("/api/shopify-callback", payload)
    .then((response) => response.data);
}

export function shopifyAuth(payload) {
  return axios.post("/shopify_auth", payload).then((response) => response.data);
}

export function changPassword(payload) {
  return axios
    .post("/api/user/change-password", payload)
    .then((response) => response.data);
}
