<template>
  <div class="default-layout">
    <slot />
  </div>
</template>

<script>
export default {
  name: "defaultLayout",
};
</script>

<style scoped lang="scss"></style>
