import axios from "axios";
import router from "@/router";
import store from "../store";
const instance = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL, // Replace with your API base URL
  timeout: 50000, // Request timeout (optional)
});

// Add request interceptor to include accessToken in headers

instance.interceptors.request.use((config) => {
  const token = localStorage.getItem("accessToken");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});
instance.interceptors.response.use(
  (response) => response,
  (error) => {
    store.dispatch(
      "showError",
      error?.response?.data?.message || error?.message || "there was error !!"
    );
    if (error.response) {
      if (
        error.response.status === 401 &&
        window.location.pathname !== "/login"
      ) {
        // Redirect to login page
        router.push("/login");
      }
      if (error.response.status === 403) {
        // Redirect to subscription page
        router.push("/setting/subscription");
      }
    }
    return Promise.reject(error);
  }
);
export default instance;
