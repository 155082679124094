import axios from "../plugins/axios";

export function generate(payload) {
  return axios
    .post("/api/playground/upload-images", payload)
    .then((response) => response.data);
}
export function generateProductStore(payload) {
  return axios
    .post("/api/channel/generate", payload)
    .then((response) => response.data);
}
export function generateTemplate(payload) {
  return axios
    .post("/api/barcode/upload-template", payload)
    .then((response) => response.data);
}
export function getChannels() {
  return axios.get("/api/channels-list").then((response) => response.data);
}
export function getMyLanguages() {
  return axios
    .get("/api/sales-channels/active-locales")
    .then((response) => response.data);
}
export function getAllLanguages() {
  return axios.get("/api/locales").then((response) => response.data);
}

export function addLanguages(payload) {
  return axios
    .post("/api/sales-channels/activate-locales", payload)
    .then((response) => response.data);
}
export function addLanguageForProduct(id, languages) {
  return axios
    .post(`/api/products/${id}/translate`, languages)
    .then((response) => response.data);
}
export function ToggleLanguageStatus(id) {
  return axios
    .post(`/api/products-locales/${id}/toggle`)
    .then((response) => response.data);
}
export function getBarcode(payload) {
  return axios
    .post("/api/barcode/search", payload)
    .then((response) => response.data);
}
export function getResponseAi(identifier, status) {
  return axios
    .get(`/api/playground/get-response/${identifier}`, {
      params: {
        status: status,
      },
    })
    .then((response) => response.data);
}
export function getTypes(search) {
  return axios
    .get(`/api/categories/search_categories?q=${search}`)
    .then((response) => response.data);
}

export function getKeywords(ai_token) {
  return axios
    .get(`/api/products/${ai_token}/get-keywords`)
    .then((response) => response.data);
}

export function generateUIEnhancement(ai_token, keywords) {
  return axios
    .post(`/api/products/${ai_token}/enhance`, keywords)
    .then((response) => response.data);
}

export function listProduct(payload) {
  return axios
    .post(`/api/playground/list`, payload)
    .then((response) => response.data);
}

export function updateProduct(id, payload) {
  return axios
    .post(`/api/playground/update/${id}`, payload)
    .then((response) => response.data);
}
export function updateProductByAI(id, payload) {
  return axios
    .post(`/api/playground/update-by-ai/${id}`, payload)
    .then((response) => response.data);
}
export function getCategories(key = null) {
  return axios
    .get("/api/categories", {
      params: {
        key: key,
      },
    })
    .then((response) => response.data);
}

export function getProductDetails(id) {
  return axios
    .get(`/api/playground/get-product-details/${id}`)
    .then((response) => response.data);
}
export function getSubscriptionInfo() {
  return axios.get(`/api/subscription`).then((response) => response.data);
}
export function discountInfo() {
  return axios.get(`/api/discounts`).then((response) => response.data);
}

export function getBuyLink(points) {
  return axios
    .post(`/api/create-checkout-session?recurring_points=${points}`)
    .then((response) => response.data);
}
export function cancelSubscription() {
  return axios
    .post(`/api/cancel-subscription`)
    .then((response) => response.data);
}
export function getBuyPointsLink(points) {
  return axios
    .post(`/api/create-checkout-session?one_time_points=${points}`)
    .then((response) => response.data);
}

export function getPlans() {
  return axios.get(`/api/plans`).then((response) => response.data);
}
export function getVendors() {
  return axios.get(`/api/shopify/vendors`).then((response) => response.data);
}

export function getCollections() {
  return axios
    .get(`/api/shopify/collections`)
    .then((response) => response.data);
}

export function history(page, itemsPerPage, filter) {
  return axios
    .get(`/api/history?page=${page}&limit=${itemsPerPage}&filter=${filter}`)
    .then((response) => response.data);
}
export function getPayments(page, itemsPerPage) {
  return axios
    .get(`/api/payments?page=${page}&limit=${itemsPerPage}`)
    .then((response) => response.data);
}
export function channelProducts(search, itemsPerPage, cursor, channel) {
  return axios
    .get(
      `/api/channel/products?q=${search}&after=${cursor}&limit=${itemsPerPage}&channel=${channel}`
    )
    .then((response) => response.data);
}
export function deleteProduct(id) {
  return axios
    .delete(`/api/playground/delete/${id}`)
    .then((response) => response.data);
}
export function ExportCsv(idArray) {
  return axios
    .post("/api/playground/export/csv", idArray)
    .then((response) => response.data);
}
export function applyDiscount() {
  return axios.post("/api/apply-discount").then((response) => response.data);
}
export function generateData(payload) {
  return axios
    .post("/api/playground/generate", payload)
    .then((response) => response.data);
}
export function generateBarcodeData(payload) {
  return axios
    .post("/api/barcode/generate", payload)
    .then((response) => response.data);
}
export function generationRequest(payload) {
  return axios
    .post("/api/playground/store-generation-request", payload)
    .then((response) => response.data);
}
export function regenerateProduct(id, uniqueId) {
  return axios
    .get(`/api/playground/regenerate-product/${id}?uniqueId=${uniqueId}`)
    .then((response) => response.data);
}

export function addFav(payload) {
  return axios
    .post("/api/categories/favorites/add", payload)
    .then((response) => response.data);
}
export function removeFav(payload) {
  return axios
    .post("/api/categories/favorites/remove", payload)
    .then((response) => response.data);
}
