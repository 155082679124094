import { createRouter, createWebHistory } from "vue-router";
import i18n from "@/plugins/i18n";
import store from "@/store";

const routes = [
  // Redirect the root path
  {
    path: "/",
    redirect: "/wizard/select-management-way",
  },

  // --------------------------------------
  // setting pages
  {
    path: "/setting",

    children: [
      {
        path: "my-profile",
        name: i18n.global.t("My Profile"),
        component: () =>
          import(
            /* webpackChunkName: "wizard" */ "@/views/settings/MyProfile.vue"
          ),
        meta: { requiresAuth: true, layout: "dashboard" },
      },
      {
        path: "my-shop",
        name: i18n.global.t("My Shop"),
        component: () =>
          import(
            /* webpackChunkName: "wizard" */ "@/views/settings/MyShop.vue"
          ),
        meta: { requiresAuth: true, layout: "dashboard" },
      },
      {
        path: "language",
        name: i18n.global.t("Language Setting"),
        component: () =>
          import(
            /* webpackChunkName: "wizard" */ "@/views/settings/languages.vue"
          ),
        meta: { requiresAuth: true, layout: "dashboard" },
      },
      {
        path: "shop-details/:shopName",
        name: i18n.global.t("My Shop Details"),
        component: () =>
          import(
            /* webpackChunkName: "wizard" */ "@/views/settings/ShopDetails.vue"
          ),
        meta: { requiresAuth: true, layout: "dashboard" },
      },
      {
        path: "subscription",
        name: i18n.global.t("Manage Your Subscription"),
        component: () =>
          import(
            /* webpackChunkName: "wizard" */ "@/views/settings/SubscriptionDetails.vue"
          ),
        meta: { requiresAuth: true, layout: "dashboard" },
      },
      {
        path: "points",
        name: i18n.global.t("Purchase Credits"),
        component: () =>
          import(
            /* webpackChunkName: "wizard" */ "@/views/settings/points.vue"
          ),
        meta: { requiresAuth: true, layout: "dashboard" },
      },
      {
        path: "subscribe",
        name: i18n.global.t("Subscribe"),
        component: () =>
          import(
            /* webpackChunkName: "wizard" */ "@/views/settings/points.vue"
          ),
        meta: { requiresAuth: true, layout: "dashboard" },
      },
      {
        path: "/cancel-payment",
        name: i18n.global.t("Cancel Payment"),
        redirect: "/setting/subscribe?fail=true",
      },
    ],
  },

  // --------------------------------------
  // Success Payment page
  {
    path: "/success-payment",
    name: i18n.global.t("Success Payment"),
    component: () =>
      import(
        /* webpackChunkName: "wizard" */ "@/views/settings/successPayment.vue"
      ),
    meta: { requiresAuth: true, layout: "dashboard" },
  },

  // --------------------------------------
  // history page
  {
    path: "/history",
    name: i18n.global.t("My Products"),
    component: () =>
      import(/* webpackChunkName: "wizard" */ "@/views/history/history.vue"),
    meta: { requiresAuth: true, layout: "dashboard" },
  },

  // --------------------------------------
  // Contact page
  {
    path: "/contact",
    name: i18n.global.t("Contact us"),
    component: () => import("@/views/contact/Contact.vue"),
    meta: { requiresAuth: true, layout: "dashboard" },
  },

  // --------------------------------------
  // wizard pages
  {
    path: "/wizard",
    children: [
      {
        path: "select-management-way",
        name: i18n.global.t("AI-Powered Product Listing Management"),
        component: () =>
          import(
            /* webpackChunkName: "wizard" */ "../views/wizard/selectManagement.vue"
          ),
        meta: { requiresAuth: true, layout: "dashboard" },
      },
      {
        path: "select-category",
        name: i18n.global.t("Select Product Category"),
        component: () =>
          import(
            /* webpackChunkName: "wizard" */ "../views/wizard/images/selectCategory.vue"
          ),
        meta: { requiresAuth: true, layout: "dashboard" },
      },
      {
        path: "search-barcode",
        name: i18n.global.t("Scan or Enter Barcode"),
        component: () =>
          import(
            /* webpackChunkName: "wizard" */ "@/views/wizard/barcode/searchBarcode.vue"
          ),
        meta: { requiresAuth: true, layout: "dashboard" },
      },
      {
        path: "products-store",
        name: i18n.global.t("Import and Enhance Products from Your Store"),
        component: () =>
          import(
            /* webpackChunkName: "wizard" */ "@/views/wizard/store/productsStore.vue"
          ),
        meta: { requiresAuth: true, layout: "dashboard" },
      },
      {
        path: "products-store/products",
        name: i18n.global.t("Enhance Products from Your Store"),
        component: () =>
          import(
            /* webpackChunkName: "wizard" */ "@/views/wizard/store/enhanceProductStore.vue"
          ),
        meta: { requiresAuth: true, layout: "dashboard" },
      },
      {
        path: "upload-images",
        name: i18n.global.t("Provide Listapro with Data"),
        component: () =>
          import(
            /* webpackChunkName: "wizard" */ "@/views/wizard/images/UploadImage.vue"
          ),
        meta: { requiresAuth: true, layout: "dashboard" },
      },
      {
        path: "listing",
        name: i18n.global.t("Listing"),
        component: () =>
          import(/* webpackChunkName: "wizard" */ "@/views/wizard/listing.vue"),
        meta: { requiresAuth: true, layout: "dashboard" },
      },
      {
        path: "review",
        name: i18n.global.t("Review Product"),
        component: () =>
          import(/* webpackChunkName: "wizard" */ "@/views/wizard/review.vue"),
        meta: { requiresAuth: true, layout: "dashboard" },
      },
      {
        path: "review/edit/:id",
        name: i18n.global.t("Update Product Details"),
        component: () =>
          import(
            /* webpackChunkName: "wizard" */ "@/views/wizard/editProduct.vue"
          ),
        meta: { requiresAuth: true, layout: "dashboard" },
      },
      {
        path: "review/preview/:id",
        name: i18n.global.t("Product Preview"),
        component: () =>
          import(
            /* webpackChunkName: "wizard" */ "@/views/wizard/previewProduct.vue"
          ),
        meta: { requiresAuth: true, layout: "dashboard" },
      },
    ],
  },

  // --------------------------------------
  // login page
  {
    path: "/login",
    name: i18n.global.t("Log In"),
    component: () =>
      import(/* webpackChunkName: "wizard" */ "../views/auth/login.vue"),
    meta: { requiresAuth: false, layout: "default" },
  },

  // --------------------------------------
  // register page
  {
    path: "/register",
    name: i18n.global.t("Create an Account"),
    component: () =>
      import(/* webpackChunkName: "wizard" */ "../views/auth/register.vue"),
    meta: { requiresAuth: false, layout: "default" },
  },

  // --------------------------------------
  // shopify_auth page
  {
    path: "/shopify_auth",
    name: i18n.global.t("shopify authentication"),
    component: () =>
      import(/* webpackChunkName: "wizard" */ "../views/auth/shopify_auth.vue"),
    meta: { requiresAuth: false, layout: "default" },
  },

  // --------------------------------------
  // shopify_callback page
  {
    path: "/shopify_callback",
    name: i18n.global.t("authentication callback"),
    component: () =>
      import(
        /* webpackChunkName: "wizard" */ "../views/auth/auth_callback.vue"
      ),
    meta: { requiresAuth: false, layout: "default" },
  },

  // --------------------------------------
  // forgot_password page
  {
    path: "/forgot_password",
    name: i18n.global.t("Reset Password"),
    component: () => import("@/views/auth/ForgetPassword.vue"),
    meta: { requiresAuth: false, layout: "default" },
  },

  // --------------------------------------
  // check_your_email page
  {
    path: "/check_your_email",
    name: i18n.global.t("Check Your Email"),
    component: () => import("@/views/auth/CheckYourEmail.vue"),
    meta: { requiresAuth: false, layout: "default" },
  },

  // --------------------------------------
  // Set New Password page
  {
    path: "/password/reset",
    name: i18n.global.t("Create New Password"),
    component: () => import("@/views/auth/SetNewPassword.vue"),
    meta: { requiresAuth: false, layout: "default" },
  },

  // --------------------------------------
  // Not Found page
  {
    path: "/:pathMatch(.*)*",
    name: i18n.global.t("Not Found"),
    component: () => import("@/views/NotFound.vue"),
    meta: { requiresAuth: true, layout: "dashboard" },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// before route enter any page based on authentication
router.beforeEach((to, from, next) => {
  // Check if the route requires authentication
  const auth = localStorage.getItem("accessToken");

  // add name of page to the title of tap
  document.title = to.name
    ? `${i18n.global.t("Listapro")} - ${to.name}`
    : i18n.global.t("Listapro");

  if (to.meta.requiresAuth && !auth) {
    // If the route requires authentication but user is not authenticated
    next({ name: i18n.global.t("Log In") });
  } else if (
    auth &&
    (to.name === i18n.global.t("Log In") ||
      to.name === i18n.global.t("Create an Account"))
  ) {
    // Redirect authenticated users trying to access login or signup pages
    next({ name: i18n.global.t("AI-Powered Product Listing Management") });
  } else {
    // to delete any localStorage for panel if i open any page without History.vue
    if (to.name !== i18n.global.t("My Products")) {
      localStorage.removeItem("panel-display");
      localStorage.removeItem("panel-display-state");
      localStorage.removeItem("panel-product-id");
      next();
    } else {
      next();
    }
  }
});

// after enter every page ..will search if the profile info uploaded and we not have credits .. so we will show the alert error
router.afterEach((to, from, next) => {
  if (store.state.profileUploaded && store.state.pointsRemain == 0) {
    store.commit("setShowCreditsWarning", true);
  }
});
export default router;
